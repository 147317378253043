class FooterTemplateGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		$(document).on('BATComponentsLoaded', () => {
			if(document.querySelector('body').classList.contains('publishMode')) {
				const reference = window.location.hash;
	
				setTimeout(() => {
					if(reference) {
						const ele = document.getElementById(reference.replace('#', ''));
						if(ele) {
							ele.scrollIntoView({behavior: 'smooth'});
						}
					}
				}, 500);
	
				if ($('bat-producthero-gloit').length > 0 || $('bat-productlisting-gloit').length > 0 ||
						window.location.pathname.indexOf('glo--expert') > -1 || 
						window.location.pathname.indexOf('glo-expert') > -1 ||
						window.location.pathname.indexOf('checkout/onepage/success/') > -1 ||
						window.location.pathname.indexOf('checkout/cart') > -1) {
					let videoChat = `
						<script defer type="text/javascript" src="https://client.voice-able.com/load/bat_it"></script>
					`;
					if (window.location.href.indexOf('discoverglo.com') < 0) {
						videoChat = `
							<script defer type="text/javascript" src="https://dev-client.voice-able.com/load/bat_it"></script>
						`;
					}
					$(videoChat).appendTo(document.body);
				}
	
				/* 
				// rimozione chat Unyco
				if (window.location.pathname.indexOf('/account/create') < 0 && window.location.pathname.indexOf('entra-in-contatto') < 0 && window.location.pathname.indexOf('qr-code-form') < 0) {
					Commerce.getLivechatConfig().then((result) => {
						if(result && result.data && result.data.moduleConfig && result.data.moduleConfig.unyco_chat) {
							const unyco_chat = result.data.moduleConfig.unyco_chat;
							
							if(unyco_chat.enabled) {
								const unycoElement = `
									<div id="unyco_container${unyco_chat.configuration.container_id}">
										<script type="text/javascript" src="${unyco_chat.configuration.url}?code=${unyco_chat.configuration.container_code}&rnd=${unyco_chat.configuration.container_id}" async defer></script>
									</div>
								`
								const addScript = () => {
									$(unycoElement).appendTo(document.body);
									document.removeEventListener('scroll', addScript);
									document.removeEventListener('mousedown', addScript);
									document.removeEventListener('mousemove', addScript);
									document.removeEventListener('touchstart', addScript);
									document.removeEventListener('scroll', addScript);
									document.removeEventListener('keydown', addScript);
								};
	
								document.addEventListener('scroll', addScript);
								document.addEventListener('mousedown', addScript);
								document.addEventListener('mousemove', addScript);
								document.addEventListener('touchstart', addScript);
								document.addEventListener('scroll', addScript);
								document.addEventListener('keydown', addScript);
							}
						}
					});
				}
				*/
	
			}
		});
	}
	

	afterLoad() {
		this.$el = $(this);

		this.data.currentYear = new Date().getFullYear();
		let currentPathname = window.location.pathname;
		if(currentPathname.endsWith('/')) {
			currentPathname = currentPathname.slice(0, window.location.pathname.lastIndexOf('/'))
		}
		let current_pageName = currentPathname.replace('.html', '').split("/").pop();
		
		if(current_pageName === "it"){
			this.data.page="HOME"
		}else{
			this.data.page= document.title.toUpperCase().split("|")[0]
		}

		this.$el.find('.bat-footer-gloit-copyright').text(`© glo™ ${this.data.currentYear}`);
		this.$el.find('.bat-footer-gloit-location-indicator-location').text(this.data.page);

		this.feedaty();

		this.hideInsidersComponent();

		// toggle main submenu
		$('.bat-footer-glo-nav-menu .bat-icon').on('click', (e) => {
			$(e.currentTarget.parentNode.nextElementSibling).slideToggle(
				400,
				() => {
					$(e.currentTarget).toggleClass('icon-plus');
				}
			);
		});

		$('.bat-footer-glo-submenu .bat-icon').on('click', (e) => {
			$(e.currentTarget.nextElementSibling).slideToggle(400, () => {
				$(e.currentTarget).toggleClass('icon-chevron-up');
			});
		});

		$('.bat-footer-gloit-nav-menu .bat-icon').on('click', (e) => {
			$(e.currentTarget.parentNode.nextElementSibling).slideToggle(
				400,
				() => {
					$(e.currentTarget).toggleClass('icon-plus');
				}
			);
		});

		$('.bat-footer-gloit-submenu .bat-icon').on('click', (e) => {
			$(e.currentTarget.nextElementSibling).slideToggle(400, () => {
				$(e.currentTarget).toggleClass('icon-chevron-up');
			});
		});

		const socialNav = document.querySelector('.bat-footer-social-nav');
		const firstMenuColumn = document.querySelector(
			'bat-footer-gloit .bat-footer-glo-nav > div:first-of-type'
		);

		firstMenuColumn && firstMenuColumn.appendChild(socialNav);

		const healthBar = $('.health-warning');
		if (healthBar.length > 0) {
			const innerText = healthBar.find('.bat-text');
			if (innerText.length > 0) {
				const filler = this.$el.find('.health-bar-filler');
				filler.css('height', innerText[0].offsetHeight);
				$(window).on('resize', () => {
					filler.css('height', innerText[0].offsetHeight);
				})
			}
		}

		$('.lazy-section').removeClass('uk-hidden');

	}

	feedaty(){

		const menuTitle =document.querySelectorAll(".menu-title");
		menuTitle.forEach((component, index) => {
			if(component.innerText == "RECENSIONI"){
				const trustbox_widget = this.$el.find('#trustbox-widget');				
				const feedaty_widget = this.$el.find('#feedaty-desktop');
				const htmlContTrust = trustbox_widget.parent();
				const htmlContFeedaty = feedaty_widget.parent();
				const menu = component.parentElement;
				const submenu = menu.querySelector(".submenu-content");
				const margin = submenu.querySelector(".uk-margin-remove");
				margin.style.display = "none";
				$(submenu).append(htmlContTrust);
				$(submenu).append(htmlContFeedaty);
				var script = document.createElement("script");
				script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
				script.async = true;
				script.defer = true;
				$(document.head).append(script);
				var script2 = document.createElement("script");
				script2.src = "https://widget.feedaty.com/public/js/feedaty.min.js?merchant=10216839&style_ver=2021&_ver=2.1.5";
				script2.classList.add('feedaty_sdk');
				script2.async = true;
				script2.defer = true;
				$(document.head).append(script2);
				/*
				if( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 900 ) ){
					trustbox_widget.style.marginLeft = "-20px";
				}
				*/
				
			}
		});

		//remove uk-hidden class from trustpilot and feedaty widgets
		const trustpilot_widget = this.$el.find('.trustpilot-widget');
		const feedaty_widget = this.$el.find('.feedaty_widget');
		if(trustpilot_widget && feedaty_widget){
			trustpilot_widget.removeClass('uk-hidden');
			feedaty_widget.removeClass('uk-hidden');
		}
	}

	//hide a component if user is not insider
	async hideInsidersComponent() {
		const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
		const isAuthorMode = this.classList.contains('author-mode');

		//fare un check anche se l'utente oltra ad essere loggato ha loyalty_optin=1
		if(!isAuthorMode) { 

			let insidersComponents = document.querySelectorAll(".insiders-component");
			insidersComponents.forEach( (component, index) => {
				component.classList.add("uk-hidden");
			});

			let notInsidersComponents = document.querySelectorAll(".not-insiders-component");
			notInsidersComponents.forEach( (component, index) => {
				component.classList.add("uk-hidden");
			});

			let customerDetails;
			if (insidersComponents && loggedIn) {
				customerDetails = await Commerce.getCustomerDetails();
			}

			if(loggedIn && customerDetails && customerDetails.data.customer.global_loyalty_optin == '1'){
				let insidersComponents = document.querySelectorAll(".insiders-component");
				insidersComponents.forEach( (component, index) => {
					component.classList.remove("uk-hidden");
					component.classList.add("uk-animation-fade");
				});
			} else {
				let notInsidersComponent = document.querySelectorAll(".not-insiders-component");
				notInsidersComponent.forEach( (component, index) => {
					component.classList.remove("uk-hidden");
					component.classList.add("uk-animation-fade");

				});
			}
		}
	}

	beforeUpdate() {
		// console.log('FooterTemplateGlo before update');
	}

	afterUpdate() {

		var script = document.createElement("script");
		script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
		script.async = true;
		script.defer = true;
		$('head').append(script);
		// console.log('FooterTemplateGlo after update');
	}

	unload() {
		// console.log('FooterTemplateGlo after unload');
	}
}

!customElements.get('bat-footer-gloit') &&
	customElements.define('bat-footer-gloit', FooterTemplateGloIT);
